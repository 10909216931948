@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html,
  body {
    @apply h-full w-full;
  }

  html {
    font-size: var(--font-size, 10px);
  }

  body {
    @apply cursor-default text-[1.6rem] antialiased;
    @apply bg-white;
  }

  #__next {
    @apply static h-full w-full;
  }
}

@media ((hover: hover) and (pointer: fine)) {
  html {
    scrollbar-width: none;
  }

  html::-webkit-scrollbar {
    display: none;
  }

  .lenis {
    scrollbar-width: none;
  }

  .lenis::-webkit-scrollbar {
    display: none;
  }
}

@layer utilities {
  .h-svh-vh {
    height: calc(var(--vh, 1vh) * 100);
    height: calc(var(--vh, 1svh) * 100);
  }

  .h-dvh-vh {
    height: calc(1vh * 100);
    height: calc(1dvh * 100);
  }

  .min-h-svh-vh {
    min-height: calc(var(--vh, 1vh) * 100);
    min-height: calc(var(--vh, 1svh) * 100);
  }

  .hero-h-svh-vh {
    min-height: calc(var(--vh, 1vh) * 100);
    min-height: calc(var(--vh, 1svh) * 100);
  }

  @media (min-width: 768px) {
    .hero-h-svh-vh {
      min-height: auto;
      height: calc(var(--vh, 1vh) * 100);
      height: calc(var(--vh, 1svh) * 100);
    }
  }

  .line-wrap,
  .word-wrap {
    overflow: hidden;
    display: inline-block;
    vertical-align: top;
  }

  .line-wrap {
    /* margin: -0.2em 0; */
  }

  .line-wrap .line {
    /* padding: 0.1em 0; */
  }

  .line-wrap .line:empty {
    margin-top: 1rem;
  }
}

#stage.ih-svh-vh {
  height: calc(var(--vh, 1vh) * 100) !important;
  height: calc(var(--vh, 1svh) * 100) !important;
}

.gm-style iframe + div {
  border: none !important;
}
